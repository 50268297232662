import { Col, List, notification, Row, Space, Table, Typography } from 'antd'
import { FC, ReactNode, useState } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import { useDLE } from 'rest-hooks'
import { Button } from 'src/sdk/components/form'
import { HorizontalSpace, VerticalSpace } from 'src/sdk/components/layout'
import { SectionLoader } from 'src/sdk/components/loader'
import { Result } from 'src/sdk/components/result/Result'
import { BreakpointMax } from 'src/sdk/components/screen/Breakpoint'
import { Money } from 'src/sdk/components/text'
import { usePrivateConfig, withPrefix } from 'src/sdk/contexts/Config'
import { useNotification } from 'src/sdk/contexts/Notification'
import { OrderEntity } from 'src/sdk/datasource/order'
import { TransactionEntity } from 'src/sdk/datasource/transaction'
import { Capitalize } from 'src/sdk/helpers/strings'
import { OrderDetails } from '../account/tabs/order/OrderDetails'
import { InvoicePayButton } from './InvoicePay'

type SummaryListItemProps = {
  id: Data.ID
  title: string
  description: string | ReactNode
}
const SummaryListItem: FC<SummaryListItemProps> = ({ id, title, description }) => (
  <List.Item>
    <Row align={'top'} justify={'space-between'} wrap={false} key={id}>
      <Col flex={'33%'}>
        <Typography.Text type={'secondary'}>{title}</Typography.Text>
      </Col>
      <Col flex={'auto'} style={{ textAlign: 'right' }}>
        <Typography.Text strong>{description}</Typography.Text>
      </Col>
    </Row>
  </List.Item>
)

const SummaryRow: FC<Data.Source<SummaryListItemProps[]>> = ({ data }) => (
  <List itemLayout={'vertical'} dataSource={data} renderItem={(props) => <SummaryListItem {...props} />} />
)

const TransactionDetails: FC<Data.Identified> = ({ id }) => {
  const { company } = usePrivateConfig()
  const { data: transaction, loading } = useDLE(TransactionEntity.detail(), id ? { id: id } : null)
  const { notifyOnError } = useNotification()
  const [sending, setSending] = useState<boolean>(false)
  const { data: order, loading: orderLoading } = useDLE(
    OrderEntity.detail(),
    transaction?.orderId ? { id: transaction?.orderId } : null,
  )
  const isMobile = useMediaQuery({ maxWidth: BreakpointMax.MD })

  const sendTransactionEmail = async () => {
    setSending(true)
    await TransactionEntity.sendByEmail({ id })
      .then(() => {
        notification.success({
          message: 'Email sent',
        })
      })
      .catch(() => notifyOnError)
      .finally(() => setSending(false))
  }

  return loading ? (
    <SectionLoader />
  ) : transaction ? (
    <VerticalSpace size={16}>
      <Row align={'middle'} justify={'space-between'} gutter={[20, 40]}>
        <Col span={24}>
          <Space
            direction={isMobile ? 'vertical' : 'horizontal'}
            style={{ width: '100%', justifyContent: 'space-between' }}
          >
            <Typography.Title level={2}>Invoice Details </Typography.Title>
            <Typography.Title level={5}>Invoice #{id}</Typography.Title>
          </Space>
        </Col>
      </Row>
      <Row justify={'space-between'} gutter={50} style={{ marginBottom: 32 }}>
        <Col span={12} lg={{ span: 11 }}>
          <VerticalSpace size={24}>
            <VerticalSpace size={0}>
              <Typography.Text strong>{company.name}</Typography.Text>
              {company.fullAddress && <Typography.Text>{company.fullAddress}</Typography.Text>}
              {company.cityStateZip && <Typography.Text>{company.cityStateZip}</Typography.Text>}
            </VerticalSpace>
            <VerticalSpace size={0}>
              <Typography.Title level={4}>Billed To</Typography.Title>
              <Typography.Text strong>{transaction.customer.fullName}</Typography.Text>
              <Typography.Text>{transaction.billingAddress?.fullAddress}</Typography.Text>
              <Typography.Text>{transaction.billingAddress?.cityStateZip}</Typography.Text>
            </VerticalSpace>
          </VerticalSpace>
        </Col>

        <Col span={12} lg={{ span: 8 }}>
          <SummaryRow
            data={[
              {
                id: 'createdOn',
                title: 'Invoiced',
                description: <FormattedDate value={`${transaction?.chargedOn}Z`} />,
              },
              {
                id: 'status',
                title: 'Status',
                description: Capitalize(transaction?.status),
              },
              {
                id: 'balance',
                title: 'Balance Due',
                description: <Money>{transaction?.status === 'invoiced' ? transaction?.amountDue : 0}</Money>,
              },
            ]}
          />
          {transaction.status === 'invoiced' && transaction.amountDue > 0 && (
            <InvoicePayButton transaction={transaction} />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {orderLoading ? (
            <SectionLoader />
          ) : order ? (
            <OrderDetails includePayments={false} includeFooter={false} includeHeader={false} order={order} />
          ) : (
            <Table
              className={withPrefix('transaction-details-totals')}
              pagination={false}
              columns={[
                {
                  title: 'Description',
                  key: 'title',
                  dataIndex: 'title',
                },
                {
                  title: 'Total',
                  key: 'description',
                  dataIndex: 'description',
                  align: 'right',
                },
              ]}
              dataSource={transaction.data.map((item, index) => ({
                key: index,
                title: item.referenceItem,
                description: <Money>{item.amount}</Money>,
              }))}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24} lg={{ span: 8, offset: 16 }}>
          <VerticalSpace align={'end'} className={withPrefix('payment-totals')}>
            <HorizontalSpace>
              <Typography.Text>Subtotal:</Typography.Text>
              <Money strong>{transaction.subTotal}</Money>
            </HorizontalSpace>

            {transaction.discount || order?.discountTotal ? (
              <HorizontalSpace>
                <Typography.Text>Discount:</Typography.Text>
                <Money strong type={'danger'}>
                  {transaction.discount * -1 || (order && order?.discountTotal * -1)}
                </Money>
              </HorizontalSpace>
            ) : null}

            <HorizontalSpace>
              <Typography.Text>
                <FormattedMessage id={'tax'} defaultMessage={'Tax'} />:
              </Typography.Text>
              <Money strong>{transaction.tax}</Money>
            </HorizontalSpace>
            {transaction.refundedAmount > 0 && (
              <HorizontalSpace>
                <Typography.Text>Refund:</Typography.Text>
                <Money strong type={'danger'}>
                  {transaction.refundedAmount * -1}
                </Money>
              </HorizontalSpace>
            )}
            <HorizontalSpace>
              <Typography.Text>Total:</Typography.Text>
              <Money strong>{transaction.amountDue}</Money>
            </HorizontalSpace>
          </VerticalSpace>
        </Col>
        <Col span={24} lg={6}>
          <Button style={{ marginTop: 20 }} loading={sending} onClick={sendTransactionEmail} type={'primary'} block>
            Send by Email
          </Button>
        </Col>
      </Row>
    </VerticalSpace>
  ) : (
    <Result.NotFound title={'Not Found'} subTitle={'This transaction could not be found'} />
  )
}

export { TransactionDetails, SummaryListItem }
