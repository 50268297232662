import { Typography } from 'antd'
import { FC } from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import Table from 'src/sdk/components/table/Table'
import { withPrefix } from 'src/sdk/contexts/Config'
import { PaymentMethodBrand } from 'src/sdk/datasource/config'
import { PaymentTypeLong, TransactionEntity, TransactionStatus } from 'src/sdk/datasource/transaction'
import { Capitalize } from 'src/sdk/helpers/strings'
import IvyIcon from '../../sdk/components/icon'
import { HorizontalSpace, VerticalSpace } from '../../sdk/components/layout'
import { PaymentBrand } from '../../sdk/components/payment-brand/PaymentBrand'
import { BreakpointMin } from '../../sdk/components/screen/Breakpoint'
import { Money } from '../../sdk/components/text'

const TransactionDesktopColumn = [
  {
    width: '8%',
    key: 'brand',
    render: (data: TransactionEntity) =>
      (() => {
        switch (data.paymentType) {
          case 'creditcard':
            return (
              <PaymentBrand
                size={32}
                type={'small'}
                brand={data.brand?.toLowerCase() as PaymentMethodBrand}
                fallbackType={'creditcard'}
              />
            )
          case 'ach':
            return (
              <PaymentBrand
                size={24}
                type={'full'}
                brand={data.brand?.toLowerCase() as PaymentMethodBrand}
                fallbackType={'bank'}
              />
            )
          default:
            return <IvyIcon style={{ padding: 4 }} type={'custom/cash'} />
        }
      })(),
  },
  {
    width: '30%',
    key: 'description',
    render: (data: TransactionEntity) => (
      <VerticalSpace size={0}>
        {data.paymentType === 'invoice' ? (
          <Typography.Text>Invoice #{data.id}</Typography.Text>
        ) : (
          <>
            <Typography.Text>{PaymentTypeLong[data.paymentType]}</Typography.Text>
            <Typography.Text>
              {Capitalize(data.brand)} •••• {data.lastFour}
            </Typography.Text>
          </>
        )}
        <HorizontalSpace split={'-'}>
          <FormattedDate value={`${data.chargedOn}`} />
          <FormattedTime value={`${data.chargedOn}`} />
        </HorizontalSpace>
      </VerticalSpace>
    ),
  },
  {
    key: 'status',
    dataIndex: 'status',
    render: (status: TransactionStatus) => ({
      children: Capitalize(status.replace('_', ' '), true),
      props: {
        align: 'center',
      },
    }),
  },
  {
    key: 'amount',
    render: (data: TransactionEntity) => ({
      children: <Money>{data.amount}</Money>,
      props: {
        align: 'right',
      },
    }),
  },
]

const TransactionMobileColumn = [
  {
    width: '15%',
    key: 'brand',
    render: (data: TransactionEntity) => {
      if (data.paymentType === 'creditcard') {
        return (
          <PaymentBrand
            type={'small'}
            brand={data.brand?.toLowerCase() as PaymentMethodBrand}
            fallbackType={'creditcard'}
          />
        )
      } else if (data.paymentType === 'ach') {
        return (
          <PaymentBrand type={'full'} brand={data.brand?.toLowerCase() as PaymentMethodBrand} fallbackType={'bank'} />
        )
      }

      return <IvyIcon style={{ padding: 4 }} type={'custom/cash'} />
    },
  },
  {
    key: 'description',
    render: (data: TransactionEntity) => (
      <VerticalSpace size={0}>
        <Typography.Text>{PaymentTypeLong[data.paymentType]} Payment</Typography.Text>
        <Typography.Text>
          {Capitalize(data.brand)} •••• {data.lastFour}
        </Typography.Text>
        <Typography.Text>{Capitalize(data.status)}</Typography.Text>
        <HorizontalSpace split={'-'}>
          <FormattedDate value={`${data.chargedOn}`} />
          <FormattedTime value={`${data.chargedOn}`} />
        </HorizontalSpace>
      </VerticalSpace>
    ),
  },
  {
    key: 'amount',
    render: (data: TransactionEntity) => ({
      children: <Money>{data.amount}</Money>,
      props: {
        align: 'right',
      },
    }),
  },
]

type TransactionPaymentTableProps = {
  emptyMessage?: string
} & Data.SourceNullable<TransactionEntity[]>

const TransactionPaymentTable: FC<TransactionPaymentTableProps> = ({ data, emptyMessage = 'No transactions' }) => {
  var isDesktop = useMediaQuery({ minWidth: BreakpointMin.LG })

  return data && data.length ? (
    <Table
      className={withPrefix('transactions-table')}
      style={{ width: '100%' }}
      columns={isDesktop ? TransactionDesktopColumn : TransactionMobileColumn}
      rowKey={(item) => `${item.id}`}
      dataSource={data}
      showHeader={false}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  ) : (
    <Typography.Text>{emptyMessage}</Typography.Text>
  )
}

export default TransactionPaymentTable
