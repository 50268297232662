import { Entity, Resource } from '@rest-hooks/rest'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { DirectionType } from 'antd/lib/config-provider'
import { SiderTheme } from 'antd/lib/layout/Sider'
import { ColorKeys } from '../themes/default'
import defaultVars from './../themes/default.json'
import { CompanyEntity } from './company'
import { DomainConfig } from './domain'
import { ApiResource } from './entity'

export type PaymentMethodBrand =
  | 'alipay'
  | 'amex'
  | 'barclays-bank-plc'
  | 'diners'
  | 'discover'
  | 'elo'
  | 'hiper'
  | 'hipercard'
  | 'jcb'
  | 'maestro'
  | 'mastercard'
  | 'mastercard-icon'
  | 'paypal'
  | 'paypal-icon'
  | 'unionpay'
  | 'visa'
  | 'visa-icon'
  | 'ally-bank'
  | 'bank-of-america'
  | 'truist'
  | 'bbva-compass'
  | 'capital-one-bank'
  | 'citibank'
  | 'citizens-bank'
  | 'fifth-third-bank'
  | 'hsbc-bank-usa'
  | 'jp-morgan-chase-bank'
  | 'keybank'
  | 'navy-federal-credit-union'
  | 'pnc-bank'
  | 'regions-bank'
  | 'td-bank'
  | 'us-bank'
  | 'wells-fargo-bank'

export type PageConfig = {
  readonly name: string
  readonly uri: string
  readonly path?: string
  icon?: Design.IvyIcon
  readonly membersOnly: boolean
  readonly enabled: boolean
  readonly nav?: boolean
  readonly order: number
}

// export type IntlLocale = 'en-us' | 'en-gb' | 'ar-ae' | 'it-it' | 'fr-fr' | 'bn-bd' | 'en-ca'

class IntlConfig extends Entity {
  readonly rtl: boolean = false
  readonly currency: string = 'USD'
  readonly locale: string = 'en-us'
  readonly timezone: string = 'UTC'
  readonly messages: Record<string, Record<string, string>> = { en: {}, es: {} }

  get direction(): DirectionType {
    return this.rtl ? 'rtl' : 'ltr'
  }

  get currencySymbol(): string {
    const currency = this.currency
    return (0)
      .toLocaleString(this.locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })
      .replace(/\d/g, '')
      .trim()
  }

  get countryLocale(): string {
    const value = this.locale.split('-')
    return value.length > 1 ? value[1] : this.locale
  }

  get languageLocale(): string {
    const value = this.locale.split('-')
    return value.length > 1 ? value[0] : this.locale
  }

  get defaultMessages(): Record<string, string> {
    return this.messages[this.languageLocale]
  }

  pk() {
    return 'intl-config'
  }
}

export type ProcessorCompany =
  | ''
  | 'authorize.net'
  | 'authorize.netv2'
  | 'braintree'
  | 'cardconnect'
  | 'gocardless'
  | 'networkinternational'
  | 'paya'
  | 'slimcd'
  | 'squarepos'
  | 'stripe'
  | 'stripev2'
  | 'toast'
  | 'wepay'

export type ProcessorIntegration = 'plaid'

type ProccessorIntegrationConfig = {
  name: ProcessorIntegration
  key: string
}

class ProcessorConfig extends Entity {
  readonly id: number = 0
  readonly name: ProcessorCompany = ''
  readonly uri: string = ''
  readonly key: string = ''
  readonly cardsAccepted: PaymentMethodBrand[] = []
  readonly acceptAch: boolean = false
  readonly default: boolean = false
  readonly integration?: ProccessorIntegrationConfig

  pk(): string {
    return `${this.id}`
  }
}

export type SocialItem = {
  title: string
  icon: string
  link: string
}

type AnalyticsProps = {
  google?: string
}

type SocialMetaProps = {
  readonly title: string
  readonly image: string
  readonly video: string
}

type SocialProps = {
  readonly facebook: string
  readonly google: string
  readonly twitter: string
  readonly linkedIn: string
  readonly instagram: string
  readonly meta: SocialMetaProps
}

type LayoutImageItem = {
  readonly src: string
  readonly width: number
  readonly justify: 'left' | 'right' | 'center'
}

type LoginLogo = {
  readonly desktop?: LayoutImageItem
  readonly mobile?: LayoutImageItem
}

class LayoutProps extends Entity {
  readonly theme: string = 'light'
  readonly basicSize: SizeType = 'middle'
  readonly loginLogo?: LoginLogo
  readonly pdfLogo?: string
  readonly footer: boolean = true
  pk() {
    return 'layout-props'
  }
}

export type LogoType = 'full' | 'icon+text' | 'icon' | 'text'

export class NavigationLogo {
  readonly justify: 'left' | 'right' | 'center' = 'left'
  readonly expanded: LogoType = 'icon+text'
  readonly collapsed: LogoType = 'icon'
}

class NavigationLogoBreakpoint {
  readonly justify?: 'left' | 'center' | 'right'
  readonly desktop: NavigationLogo = new NavigationLogo()
  readonly mobile: NavigationLogo = new NavigationLogo()
}

class NavigationProps extends Entity {
  readonly mode: 'vertical' | 'horizontal' | 'burger' = 'vertical'
  readonly theme: 'light' | 'dark' = 'dark'
  readonly logo: NavigationLogoBreakpoint = new NavigationLogoBreakpoint()
  pk() {
    return 'navigation-props'
  }
}

class SiderProps extends Entity {
  readonly width: number = 280
  readonly collapsedWidth: number = 112
  readonly theme: SiderTheme = 'dark'
  readonly hidden: boolean = false
  pk() {
    return 'sider-props'
  }
}

export class SloganProps extends Entity {
  readonly title: string = ''
  readonly subtitle: string = ''
  readonly image?: string
  readonly mobile?: boolean
  readonly mobileOnly?: boolean
  pk() {
    return `slogan-props-${this.title}`
  }
}

type FooterProps = {
  readonly hidden: boolean
}

type LogoSrcProps = {
  readonly text: string
  readonly icon: string
  readonly full: string
  readonly fullSize?: number
  readonly textSize?: number
  readonly iconSize?: number
}

class LogoProps extends Entity {
  readonly standard?: LogoSrcProps
  readonly inverted?: LogoSrcProps

  pk() {
    return 'logo-props'
  }
}

type FontItem = {
  name: string
  url: string
}

type GuestPassSettings = {
  requireEmail?: boolean
  requirePhone?: boolean
}

export type PortalSettings = {
  enableRegistration?: boolean
  activeMembers?: boolean
  showNominations?: boolean
  showFaq?: boolean
  guestPass: GuestPassSettings
  guestDirectory: boolean
}

export type PortalFeatures = {
  surveys: {
    nomination: boolean
    personalDetails: boolean
    contact: boolean
    guestPass: boolean
    registration: boolean
  }
  editPrimaryInfo?: boolean
  sms: boolean
  appId?: number
}

class FontProps extends Entity {
  readonly mainFont?: FontItem
  readonly secondaryFont?: FontItem
  pk() {
    return 'font-props'
  }
}

export type LessVars = typeof defaultVars & Record<string, string | number>

export type PortalAssets = {
  fonts?: string[]
}

interface PortalComponent {
  readonly title: string
  readonly enabled: boolean
}

interface PortalAccountSection {
  readonly components: {
    memberships: PortalComponent
    profile: PortalComponent
    wallet: PortalComponent
    billing: PortalComponent
    contact: PortalComponent
  }
}
interface PortalSections {
  readonly account: PortalAccountSection
}

export class PropsConfig extends Entity {
  readonly layout: LayoutProps = LayoutProps.fromJS()
  readonly navigation: NavigationProps = NavigationProps.fromJS()
  readonly sider: SiderProps = SiderProps.fromJS()
  readonly footer?: FooterProps
  readonly colors: { [key in ColorKeys]?: string | number } = {}
  readonly logo: LogoProps = new LogoProps()
  readonly social?: SocialProps
  readonly slogans: SloganProps[] = []
  readonly fonts: FontProps = FontProps.fromJS()
  readonly assets: PortalAssets = {}
  readonly sections: PortalSections = {} as PortalSections
  readonly favicon: string = ''

  // get varsObject(): { [name: string]: string } {
  //   return Object.entries(this.colors).reduce((obj, [key, val]) => {
  //     const modifiedKey = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
  //     return {
  //       ...obj,
  //       ...{ [modifiedKey]: val },
  //     }
  //   }, {})
  // }

  // get lessVars(): LessVars {
  //   // Variables are automatically converted to camelCase
  //   // They need to be converted to dash-case for CSS parsing
  //   return Object.entries(this.colors).reduce((obj, [key, val]) => {
  //     const modifiedKey = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
  //     return {
  //       ...obj,
  //       ...{ [modifiedKey]: val },
  //     }
  //   }, {}) as LessVars
  // }

  get fontList() {
    let fontArray = this.assets.fonts ?? []
    const fontObj = this.fonts
    if (fontObj.mainFont && !fontArray.includes(fontObj.mainFont.url)) {
      fontArray.push(fontObj.mainFont.url)
    }
    if (fontObj.secondaryFont && !fontArray.includes(fontObj.secondaryFont.url)) {
      fontArray.push(fontObj.secondaryFont.url)
    }
    return fontArray.map((url) => {
      return {
        href: url,
        rel: 'stylesheet',
        type: 'text/css',
      }
    })
  }

  pk() {
    return 'props-config'
  }
}

class CompanyConfig extends ApiResource {
  static readonly urlRoot = '/config'
  readonly company: CompanyEntity = new CompanyEntity()

  static schema = {
    company: CompanyEntity,
  }
}

type PortalPlan = 'Lounge' | 'Plus'

class PortalConfig extends ApiResource {
  static readonly urlRoot = '/config'
  readonly id: number = 0
  readonly domainId: number = 0
  readonly companyId: number = 0
  readonly environment: string = ''
  readonly token: string = ''
  readonly key: string = ''
  readonly debug: boolean = false
  readonly plan: PortalPlan = 'Plus'
  readonly analytics: AnalyticsProps = {}
  readonly pages: PageConfig[] = []
  readonly props: PropsConfig = new PropsConfig()
  readonly theme: { [key in ColorKeys]?: string | number } = {}
  readonly intl: IntlConfig = new IntlConfig()
  readonly features?: PortalFeatures
  readonly settings?: PortalSettings
  readonly processorId?: number
  readonly processors: ProcessorConfig[] = []
  readonly globalNetwork?: {
    enabled: boolean
    id?: number
  }
  readonly domainName: string = ''

  static useFetchInit(init: RequestInit): RequestInit {
    return { ...init, headers: { ...init.headers } }
  }

  get defaultProcessor() {
    return this.processors.find((p) => p.default)
  }

  get stripeProcessor() {
    return this.processors.find((p) => p.name === 'stripe' || p.name === 'stripev2')
  }
  get achProcessor() {
    return this.processors.find((p) => p.acceptAch && p.name === 'gocardless')
  }

  get varsObject(): { [name: string]: string } {
    return Object.entries(this.theme).reduce((obj, [key, val]) => {
      const modifiedKey = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
      return {
        ...obj,
        ...{ [modifiedKey]: val },
      }
    }, {})
  }

  get lessVars(): LessVars {
    // Variables are automatically converted to camelCase
    // They need to be converted to dash-case for CSS parsing
    return Object.entries(this.theme).reduce((obj, [key, val]) => {
      const modifiedKey = key.replace(/[A-Z0-9]/g, (letter) => `-${letter.toLowerCase()}`)
      return {
        ...obj,
        ...{ [modifiedKey]: val },
      }
    }, {}) as LessVars
  }
}

class Handshake extends ApiResource {
  static readonly urlRoot = '/config'
  readonly portal: PortalConfig = new PortalConfig()
  readonly company: CompanyEntity = new CompanyEntity()

  static schema = {
    portal: PortalConfig,
    company: CompanyEntity,
  }

  static useFetchInit(init: RequestInit): RequestInit {
    return { ...init, headers: { ...init.headers } }
  }

  static updateConfig<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate()
    return super.partialUpdate().extend({
      getOptimisticResponse: (snap: any, params: any, body: any) => {
        const { data } = snap.getResponse(Handshake.detail())
        return {
          id: 'the_only_one',
          portal: {
            ...params,
          },
          company: data.company,
        }
      },
      fetch: (params: Partial<DomainConfig>) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `/admin/api/domains/${params.domainId}`,
          }),
          {},
          {
            config: params,
          },
        )
      },
      schema: this,
    })
  }

  pk() {
    return 'the_only_one'
  }
}

export { PortalConfig, IntlConfig, ProcessorConfig, Handshake }
