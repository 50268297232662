import { Col, Input, Layout, Row, Typography } from 'antd'
import moment from 'moment'
import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useController, useDLE } from 'rest-hooks'
import { ApiError } from 'src/sdk/api'
import { Button, Item } from 'src/sdk/components/form'
import IvyIcon from 'src/sdk/components/icon'
import { VerticalSpace } from 'src/sdk/components/layout'
import { Flex } from 'src/sdk/components/layout/Grid'
import { OverlayLoader } from 'src/sdk/components/loader'
import { Result } from 'src/sdk/components/result/Result'
import { BreakpointMax } from 'src/sdk/components/screen/Breakpoint'
import { Skeleton } from 'src/sdk/components/skeleton/Skeleton'
import { EmailLink } from 'src/sdk/components/text'
import { useAuth } from 'src/sdk/contexts/Auth'
import { useCompanyConfig, useIntlFormatter, withPrefix } from 'src/sdk/contexts/Config'
import { useFeatureToggles } from 'src/sdk/contexts/Feature'
import { useNotification } from 'src/sdk/contexts/Notification'
import { MembershipCardEntity } from 'src/sdk/datasource/membership'
import { PaymentMethod } from 'src/sdk/datasource/payment'
import { TransactionEntity } from 'src/sdk/datasource/transaction'
import { usePaymentEndpoint } from 'src/sdk/hooks/usePaymentEndpoint'
import useWallet from 'src/sdk/hooks/useWallet'
import PaymentMethodSelect from '../account/tabs/wallet/payment-methods/PaymentMethodSelect'
import PublicView from '../public'
import './PayWall.less'

interface PayWallMessageProps {
  loading: boolean
  onContinue: () => void
}

const PayWallMessage: FC<PayWallMessageProps> = ({ loading, onContinue }) => {
  const { hasStripeProcessor } = useFeatureToggles()
  const { name, email } = useCompanyConfig()
  const isMobile = useMediaQuery({ maxWidth: BreakpointMax.SM })

  return (
    <VerticalSpace>
      <Typography.Title level={isMobile ? 2 : 1} type={'danger'}>
        Membership Past Due
      </Typography.Title>
      {hasStripeProcessor ? (
        <Fragment>
          <Typography.Paragraph type={'secondary'}>
            Your membership to <Typography.Text strong>{name}</Typography.Text> is currently inactive due to failed
            payment. It is likely we have an out of date payment method on file. Please proceed to make a payment to
            activate your membership for instant access.
          </Typography.Paragraph>
          <Typography.Paragraph type={'secondary'}>
            If you need assistance, please contact us at <EmailLink title={email} />
          </Typography.Paragraph>
          <Button loading={loading} block type={'primary'} onClick={onContinue}>
            Activate Your Membership
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <Typography.Paragraph type={'secondary'}>
            Your membership to <Typography.Text strong>{name}</Typography.Text> is currently inactive due to failed
            payment. It is likely we have an out of date payment method on file.
          </Typography.Paragraph>
          <Typography.Paragraph type={'secondary'}>
            To discuss your membership and the possibility of re-activating, please contact <EmailLink title={email} />
          </Typography.Paragraph>
        </Fragment>
      )}
    </VerticalSpace>
  )
}

const PayWallNotRenewableMessage: FC = () => {
  const { email } = useCompanyConfig()
  const isMobile = useMediaQuery({ maxWidth: BreakpointMax.SM })
  return (
    <VerticalSpace>
      <Typography.Title level={isMobile ? 2 : 1} type={'danger'}>
        Membership is On Hold and Past Due
      </Typography.Title>
      <Typography.Paragraph type={'secondary'}>
        Your membership payment is overdue and account has been placed on hold.
      </Typography.Paragraph>
      <Typography.Paragraph type={'secondary'}>
        To re-activate your account, please contact <EmailLink title={email} />
      </Typography.Paragraph>
    </VerticalSpace>
  )
}

interface PayWallPaymentProps {
  id: Data.ID
  onSuccess: (transaction: TransactionEntity) => void
  onError: (error: ApiError) => void
}

const PayWallPayment: FC<PayWallPaymentProps> = ({ id, onSuccess, onError }) => {
  const { notifyOnError } = useNotification()
  const [paying, setPaying] = useState(false)
  const [renewable, setRenewable] = useState(true)
  const { wallet } = useWallet()
  const { data: membership, loading } = useDLE(MembershipCardEntity.detail(), { id: id })
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>()
  const { currency } = useIntlFormatter()
  const { createPaymentEndpoint, submitPayment } = usePaymentEndpoint()

  const MembershipPay = createPaymentEndpoint({
    url: (params) => `${MembershipCardEntity.urlRoot}/${id}/payments`,
  })

  useEffect(() => {
    // Try to pull the user out of the paywall view if the membership is active
    // Safeguard
    membership && membership.status === 'active' && window.location.reload()
    membership && setRenewable(membership.subscription.renewable)
  }, [membership])

  const handlePayment = (id: Data.ID, paymentMethod: PaymentMethod) => {
    setPaying(true)
    submitPayment(MembershipPay, {
      id: id,
      paymentMethod,
    })
      .then((response) => {
        onSuccess(response.transaction)
      })
      .catch(notifyOnError)
      .finally(() => setPaying(false))
  }

  const handleAdd = (method: PaymentMethod) => {
    if (!membership) return
    handlePayment(membership.id, method)
  }

  const handleSubmitPayment = () => {
    if (!membership || !paymentMethod) return
    handlePayment(membership.id, paymentMethod)
  }

  const LineItems: FC = () => {
    if (!membership?.subscription) return null
    const lines = membership.subscription.lines

    return (
      <Flex direction={'horizontal'} size={16}>
        {lines.tax && lines.tax > 0 ? (
          <Item label={'Tax'} style={{ flex: 1 }}>
            <Input readOnly disabled value={currency(lines.tax)} />
          </Item>
        ) : null}
        {lines.service && lines.service > 0 ? (
          <Item label={'Service Fee'} style={{ flex: 1 }}>
            <Input readOnly disabled value={currency(lines.service)} />
          </Item>
        ) : null}
      </Flex>
    )
  }

  return (
    <OverlayLoader loading={loading || paying}>
      {renewable ? (
        <VerticalSpace size={16}>
          {membership ? (
            <VerticalSpace size={8}>
              <Typography.Title level={3} type={'secondary'} style={{ marginBottom: 0 }}>
                Your payment for {membership.title} is past due
              </Typography.Title>
              <Typography.Text type={'secondary'}>Your Membership Subscription Details</Typography.Text>
            </VerticalSpace>
          ) : (
            <VerticalSpace size={8}>
              <Skeleton width={'100%'} height={27} />
              <Skeleton width={235} height={16} />
            </VerticalSpace>
          )}
          <VerticalSpace size={8}>
            <Item label={'Membership Plan'}>
              {membership ? (
                <Input readOnly disabled value={membership.title} />
              ) : (
                <Skeleton width={'100%'} height={60} />
              )}
            </Item>
            <Item label={'Membership Plan Rate'}>
              {membership?.subscription ? (
                <Input
                  readOnly
                  disabled
                  value={`${currency(membership.subscription.rate)} / ${membership.subscription.period}`}
                />
              ) : (
                <Skeleton width={'100%'} height={60} />
              )}
            </Item>

            {membership?.subscription && membership.subscription.lastAmount !== undefined ? (
              <Item label={'Last Payment'}>
                <Input
                  readOnly
                  disabled
                  value={`${currency(membership.subscription.lastAmount || 0)} on ${moment(
                    membership.subscription.lastDate,
                  ).format('MM/DD/yyyy')}`}
                />
              </Item>
            ) : null}

            <LineItems />

            <Item label={'Balance Due'}>
              {membership?.subscription ? (
                <Input
                  readOnly
                  disabled
                  value={`${currency(membership.subscription.lines.amount)} ${
                    membership.subscription.paymentCount > 1
                      ? `(${membership.subscription.paymentCount} ${membership.subscription.periodPlural})`
                      : ''
                  }`}
                />
              ) : (
                <Skeleton width={'100%'} height={60} />
              )}
            </Item>
          </VerticalSpace>
          <PaymentMethodSelect
            defaultSave
            addCardTitle={wallet && wallet.length > 0 ? '' : 'Add a new card'}
            addCardBtnTitle={membership && `Pay ${currency(membership.subscription.lines.amount)}`}
            includeBanks={false}
            title={'Please select a payment method'}
            amount={membership?.subscription.lines.amount}
            onChange={setPaymentMethod}
            onAdd={handleAdd}
          />

          {membership?.subscription && wallet && wallet.length > 0 && (
            <Button block onClick={handleSubmitPayment} loading={paying} type={'primary'} disabled={!paymentMethod}>
              Pay {currency(membership.subscription.lines.amount)}
            </Button>
          )}
        </VerticalSpace>
      ) : (
        <PayWallNotRenewableMessage />
      )}
    </OverlayLoader>
  )
}

const PayWallSteps: FC = () => {
  const { refreshAccount, logout } = useAuth()
  const { fetch, invalidate } = useController()
  // Pre-load wallet
  const { wallet } = useWallet()
  const [error, setError] = useState<ApiError>()
  const [redirecting, setRedirecting] = useState(false)
  const [currentStep, setCurrentStep] = useState<'' | 'pay' | 'success' | 'error'>('')
  const [membershipId, setMembershipId] = useState<Data.ID>()
  const { data: memberships, loading } = useDLE(MembershipCardEntity.list(), { status: 'failed', type: 'subscription' })

  useMemo(() => {
    memberships && memberships.length > 0 && setMembershipId(memberships[0].id)
  }, [memberships, loading])

  const handleSuccess = () => {
    // Invalidate paid membership card
    invalidate(MembershipCardEntity.detail(), { id: membershipId })
    // Fetch full list
    fetch(MembershipCardEntity.list(), {})
    changeStep('success')
  }

  const changeStep = (step: '' | 'pay' | 'success' | 'error') => {
    switch (step) {
      case 'pay':
        // Need to force the previous membership fetch to invalidate
        // Pay step will re-fetch with more detail
        if (membershipId) {
          invalidate(MembershipCardEntity.detail(), { id: membershipId }).then(() => setCurrentStep('pay'))
          return
        }
        break
      case 'success':
        setCurrentStep(step)
        return
      default:
        setCurrentStep(step)
    }
  }

  const goToHomePage = () => {
    setRedirecting(true)
    // Invalidate and re-fetch account
    // failed payment flag will be removed and page will re-render
    refreshAccount()
  }

  const MembershipPaymentFailed: FC = () => (
    <Result.Error
      status={'error'}
      title={`Payment Failed`}
      subTitle={'There was a problem processing this payment.  See the details below and try again.'}
      extra={<Button onClick={() => changeStep('pay')} title={'Go Back'} wide htmlType={'button'} type={'ghost'} />}
    >
      {error &&
        (error.errorDetails ? (
          Array.isArray(error.errorDetails) ? (
            <VerticalSpace align={'center'}>
              {error.errorDetails.map((item) => (
                <Typography.Text>{item.details}</Typography.Text>
              ))}
            </VerticalSpace>
          ) : (
            <Typography.Text>{error.errorDetails.details}</Typography.Text>
          )
        ) : (
          <Typography.Text>{error.errorDescription}</Typography.Text>
        ))}
    </Result.Error>
  )

  const MembershipPaymentCompleted: FC = () => (
    <Result.Success
      status={'success'}
      title={`Thank You! Your membership has been successfully activated.`}
      subTitle={'You will receive an email confirmation shortly.'}
      extra={
        <VerticalSpace style={{ width: '100%', maxWidth: 400 }}>
          <Button block onClick={logout} title={'Logout'} type={'ghost'} />
          <Button loading={redirecting} block onClick={goToHomePage} title={'Proceed to My Club'} type={'primary'} />
        </VerticalSpace>
      }
    />
  )

  return (
    <Row justify={'center'} style={{ height: '100%' }} align='middle' className={withPrefix('paywall')}>
      <Col
        xs={20}
        md={20}
        lg={20}
        xl={18}
        xxl={14}
        className={withPrefix('paywall-content')}
        style={{ display: 'flex' }}
      >
        <Button className={withPrefix('paywall-logout')} size={'large'} type={'text'} theme={'icon'} onClick={logout}>
          <Flex justify={'center'} align={'center'} size={8}>
            Logout
            <IvyIcon type={'custom/sign-out'} />
          </Flex>
        </Button>
        {(() => {
          switch (currentStep) {
            case '':
              return <PayWallMessage loading={loading} onContinue={() => changeStep('pay')} />
            case 'pay':
              return (
                membershipId && (
                  <PayWallPayment
                    id={membershipId}
                    onSuccess={handleSuccess}
                    onError={(error) => {
                      setError(error)
                      changeStep('error')
                    }}
                  />
                )
              )
            case 'success':
              return <MembershipPaymentCompleted />
            case 'error':
              return <MembershipPaymentFailed />
          }
        })()}
      </Col>
    </Row>
  )
}

const PayWall: FC = () => {
  return (
    <Layout>
      <PublicView>
        <PayWallSteps />
      </PublicView>
    </Layout>
  )
}

export default PayWall
